import { defineStore } from 'pinia';
import { getMapFromArray } from '~/common/functions';
import { type ExistingPlace } from '~/interfaces/publisher';

export const usePlacesStore = defineStore('places', {
  state: (): { places: ExistingPlace[]; selectedPlaceIds: number[] } => ({
    places: [],
    selectedPlaceIds: [],
  }),
  getters: {
    placesMap: (state) => getMapFromArray(state.places),
    selectedPlaces(): ExistingPlace[] {
      return this.selectedPlaceIds.map((id) => this.placesMap.get(id) as ExistingPlace);
    },
  },
  actions: {
    setPlaces(payload: ExistingPlace[]) {
      this.places = [...payload];
    },
    addPlace(payload: ExistingPlace) {
      this.setPlaces([...this.places, payload]);
    },
    removePlace(payload: number) {
      this.setPlaces(this.places.filter(({ id }) => id !== payload));
    },
    setSelectedPlaceIds(payload: number[]) {
      this.selectedPlaceIds = [...payload];
    },
  },
});
